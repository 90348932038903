<template>
  <Layout>
    <div class="card px-5 py-4" style="min-height: 720px">
      <div v-if="loadingData" style="text-align: center">
        <pulse-loader color="#e83e8c" />
      </div>
      <div v-else>
        <FormulateForm v-model="values" name="vendor" @submit="submitHandler">
          <div class="row">
            <div class="col-md-6">
              <FormulateInput
                name="company_name"
                type="text"
                label="Company name"
                placeholder="Company name here"
                validation="required"
                :validationMessages="{ required: 'Company name is required.' }"
              />
            </div>
            <div class="col-md-6">
              <FormulateInput
                name="contact_name"
                type="text"
                label="Contact name"
                placeholder="Contact name here"
                validation="required"
                :validationMessages="{ required: 'Contact name required.' }"
              />
            </div>

            <div class="col-md-6">
              <FormulateInput
                name="license_no"
                type="text"
                label="License No."
                placeholder="License No. here"
                validation="required"
                :validationMessages="{
                  required: 'License number is required.',
                }"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <FormulateInput
                name="email"
                type="email"
                label="Email address"
                placeholder="Email address"
                validation="required|email"
              />
            </div>
            <div class="col-md-6">
              <FormulateInput
                name="contact_number"
                type="tel"
                label="Contact"
                placeholder="Phone number here!"
                validation="required"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <FormulateInput
                name="local_address"
                type="text"
                label="Local address"
                placeholder="Local address here"
                validation="required"
              />
            </div>
            <div class="col-md-6">
              <FormulateInput
                name="country"
                :options="countries"
                placeholder="Select a country"
                label="Country"
                type="select"
              />
            </div>
            <div class="col-md-6">
              <FormulateInput
                name="city"
                placeholder="Please enter city name"
                label="City"
                type="text"
                 validation="required"
              />
            </div>
          </div>

          <div class="mt-5 bank-details-vendor">
            <strong> Bank Details </strong>

            <div class="row">
              <div class="col-md-6">
                <FormulateInput
                  name="account_name"
                  type="text"
                  label="Account name"
                  placeholder="Account name"
                  validation="required"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  name="bank_name"
                  type="text"
                  label="Bank name"
                  placeholder="Bank name"
                  validation="required"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  name="iban"
                  type="text"
                  label="IBAN"
                  placeholder="IBAN here"
                  validation="required"
                />
              </div>
              <div class="col-md-6">
                <FormulateInput
                  name="swift_code"
                  type="text"
                  label="Swift code"
                  placeholder="Swift code here"
                  validation="required"
                />
              </div>
            </div>
          </div>

          <div class="right-align mt-4 mb-2">
            <FormulateInput
              type="submit"
              :label="updating ? 'Updating...' : 'Update Details'"
            />
          </div>
        </FormulateForm>
      </div>
    </div>
  </Layout>
</template>

<script>
import { vendor } from "@/config/api/vendor";
import { Country } from "country-state-city";
import Layout from "../../../layouts/main";
import { tribe } from "@/config/api/vendor/tribes.js";
export default {
  components: { Layout },
  data() {
    return {
      open: this.openModal,
      filterdCity: [],
      values: {},
      cities: [],
      tribeAvailable: [],
      loadingData: true,
      updating: false,
      countries: Country.getAllCountries().map((item) => {
        return {
          label: item.name,
          value: item.isoCode,
        };
      }),
    };
  },
  created() {
    this.loadAssignedVendorData();
  },

  methods: {
    loadAssignedVendorData() {
      const api = tribe.getAssignedTribeInfo;
      this.loadingData = true;
      this.generateAPI(api)
        .then((res) => {
          this.values = { ...res.data.vendor, ...res.data.vendor.bank_details };
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.loadingData = false;
        });
    },
    submitHandler(data) {
      const api = vendor.updateVendor;

      delete data["assigned_tribe"];
      delete data["password"];
      data.type = "vendor";
      api.data = {
        ...data,
        vendorId: data._id,
      };
      this.updating = true;
      this.generateAPI(api)
        .then((res) => {
          console.log("Success")
          if (res.data.vendor) {
            this.$bvToast.toast("Updated successfully!", {
              title: "Vendor",
              variant: "success",
              solid: true,
            });
          } else {
            this.$bvToast.toast(res.data.message, {
              title: "Vendor",
              variant: "danger",
              solid: true,
            });
          }
        })
        .catch((err) => {
          this.$bvToast.toast(err.response.data.message, {
            title: "Vendor",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.updating = false;
        });
    },
  },
};
</script>

<style scopped>
.bank-details-vendor {
  border: 1px dashed;
  padding: 12px;
  border-radius: 12px;
  padding-bottom: 18px;
}
</style>
